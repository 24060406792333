import React, { Fragment } from 'react';
import Hero from '../components/Hero/Hero';
import Seo from '../components/Seo/Seo';

const PrivacyPolicyPage = (): JSX.Element => (
    <Fragment>
        <Seo title="Privacy Policy" />
        <Hero height="small">
            <Hero.Body containerWidth="small" className="text-center">
                <div className="text-center w-full">
                    <h1 className="gold text-title">Privacy Policy</h1>
                </div>

            </Hero.Body>
        </Hero>
        <section className="contain containSmall space-y-2 pb-5">
            <p className="text-paragraph">Queries or Concerns  Should you want to contact us about any concern or question you may have about your personal data and how we handle them; or if you want to change or delete any personal information that we hold; or you have any other questions about our privacy policy or security matters, please contact our Data Protection Officer.</p>

            <p className="text-paragraph"> Daniel Duncombe-Davis</p>

            <p className="text-paragraph"> Managing Director</p>

            <p className="text-paragraph"> Email: office@constructsbuilding.co.uk</p>

            <p className="text-paragraph"> Telephone: 01242 243584</p>

            <p className="text-paragraph"> Personal information &amp; how we use it:  The personal information we hold relating to you and your business may differ depending on the services we execute for you and the relationship we have. We use your personal data for the following purposes:</p>

            <p className="text-paragraph"> Personal information  Such as your name, physical address, email address and telephone number, which you provide to us by using our website contact us form. We use that information to contact you regarding the provision of our services.</p>

            <p className="text-paragraph"> Invoicing information  Such as your Company name, VAT number, telephone, invoicing address, town, country, postal code, bank details, PO numbers etc. that you provide to us when you choose to buy our services. We use such information to identify you, verify your purchase of our services, and for invoicing purposes, as applicable.</p>

            <p className="text-paragraph"> Legal responsibilities  To protect our business operations and comply with UK Laws, we reserve the right to supply Government Agencies with relevant information on request. This includes but is not limited to: Police, HMRC, Trading Standards, etc.</p>

            <p className="text-paragraph"> Marketing preferences  To improve our marketing communications, we may collect information about interaction with, and responses to, our marketing communications. You will only receive marketing emails by verifying your request to receive it (i.e. double opt in email) and you can unsubscribe at any time by sending us an email to office@constructsbuilding.co.uk.</p>

            <p className="text-paragraph"> Online activity information  We may receive personal information about you when you use the Services or your activity when visiting our website; this may include your IP address, your operating system, your browser ID, your browsing activity and other online identifiers (to the extent that they are personal information), and other personal information that you provide to us online. We use the following tools to track this: Google Analytics, Godaddy.</p>

            <p className="text-paragraph"> Data we do not collect  We do not collect any Sensitive Data about you. For example details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data. We do not collect any information about criminal convictions and offences.</p>

            <p className="text-paragraph"> We do not collect any data from Minors under the age of 18. No one under the legal age requirement may provide any personal information to or through our website while using the services. We do not knowingly collect personal information from minors. In the event that we find out that we have collected personal information from a minor without verification of parental consent, this information will be deleted. If you believe that we might have any information from or about a minor, please contact us at: office@constructsbuilding.co.uk.</p>

            <p className="text-paragraph"> Your Customer Data  Location of Storage  We use secure, domain-specific email and ISO 27001 compliant cloud storage.  All storage complies with GDPR and US Privacy Shield requirements.  Not all storage data is stored in the EU, in line with best practice data storage.  Our email servers are based in the EU.  Constructs Group Ltd undertake the obligation to ensure that transfers outside the EU will be made pursuant to the Standard Contractual Clauses approved by the European Commission or any other safeguards where permissible by the applicable data protection law.  Retention of your personal information  We will keep your personal information for as long as is necessary for the purposes for which we collect it and always in accordance with any applicable statutory data retention periods.</p>

            <p className="text-paragraph"> Any redundant data will be deleted and we regularly review storage for this purpose.</p>

            <p className="text-paragraph"> Privacy Policy Modifications  Our Privacy Policy may be amended from time to time. If Constructs Group Ltd does amend this Privacy Policy, we will post notice of the changes on our home page and send a respective email to all users. Those amendments will also be posted here, so visitors are encouraged to check back regularly for any changes.</p>

            <p className="text-paragraph"> Your Rights  Unless subject to an exemption under the GDPR, you have the following rights with respect to your personal data:</p>

            <p className="text-paragraph"> The right to request a copy of the personal data which we hold about you;  The right to request that we correct any personal data if it is found to be inaccurate or out of date;  The right to request your personal data is erased where it is no longer necessary to retain such data;  The right to request that we provide you with your personal data and where possible, to transmit that data directly to another data controller, (known as the right to data portability), (where applicable i.e. where the processing is based on consent or is necessary for the performance of a contract with the data subject and where the data controller processes the data by automated means);  The right, where there is a dispute in relation to the accuracy or processing of your personal data, to request a restriction is placed on further processing;  The right to object to the processing of personal data, (where applicable i.e. where processing is based on legitimate interests, direct marketing and processing for the purposes of scientific/historical research and statistics).  If you have raised a complaint directly with Constructs Group Ltd which remains unresolved, you have the right to contact the Information Commissioner&rsquo;s Office (ICO).  Your Consent  By accepting this Privacy Policy and by granting your express consent, you accept the processing of your personal information, as set forth and for the purposes described in this Privacy Policy.</p>

            <p className="text-paragraph"> Cookies  A cookie is a small file which asks permission to be placed on your computer&rsquo;s hard drive. Once you agree, the file is added, and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</p>

            <p className="text-paragraph"> We use traffic log cookies to identify which pages are being used. This helps us analyse data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.</p>

            <p className="text-paragraph"> Overall, cookies help us provide you with a better website by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.</p>

            <p className="text-paragraph"> You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</p>

            <p className="text-paragraph"> Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.</p>

            <p className="text-paragraph"> We use cookies on luxuryconstructions.co.uk By using this site, you consent to the use of cookies.</p>

            <p className="text-paragraph"> Contact Constructs Group Ltd about this policy  Should you want to contact us about any concern or question you may have about your personal data and how we handle them, or if you want to change, delete, or change, any personal information that we hold, or you have any other question about any privacy and security matter please contact our data protection officer</p>

            <p className="text-paragraph"> Daniel Duncombe-Davis  Managing Director</p>

        </section>
    </Fragment>
);

export default PrivacyPolicyPage;

// TODO: Use a WYSIWYG/RichText component here
