import React from 'react';
import { Link } from 'gatsby';

import * as styles from './TopNavigation.module.scss';

import { useNavigationContext } from '../../../data/context/NavigationContext';

import BlankButton from '../../Buttons/BlankButton/BlankButton';
import LinkButton from '../../Buttons/LinkButton/LinkButton';
import IconMenu from '../../Icons/IconMenu';
import IconLeaf from '../../Icons/IconLeaf';
import IconArrowRight from '../../Icons/IconArrowRight';

// import logoHorizontalWhiteGoldImg from '../../../images/logo--horizontal--white-gold.svg';
import logoSmallImg from '../../../images/logo--small.svg';
import logoFullWhiteGoldImg from '../../../images/logo--full--white-gold.svg';

const TopNav = (): JSX.Element => {
    const { setSidebarNavIsOpen } = useNavigationContext();

    return (
        <nav className={styles.TopNav}>
            <div className={`contain containXLarge ${styles.TopNavInner}`}>
                <div className={styles.TopNavLinks}>
                    <BlankButton className={styles.TopNavLink} icon={<IconMenu size="24px" />} iconPosition="before" label="Menu" labelStyle="text-smallcaps" onClick={() => { setSidebarNavIsOpen(true); }} />
                    <Link to="/carbon-manifesto" className={`${styles.TopNavLink} ${styles.TopNavCarbonLink}`}>
                        <IconLeaf size="24px" />
                        <span className="text-smallcaps">Our carbon manifesto</span>
                    </Link>
                </div>
                <Link to="/" className={styles.TopNavSmallLogo}>
                    <img src={logoSmallImg} alt="Constructs Group" style={{ maxWidth: '2.5rem' }} />
                </Link>
                <Link to="/" className={styles.TopNavLargeLogo}>
                    <img src={logoFullWhiteGoldImg} alt="Constructs Group" style={{ maxWidth: '8rem' }} />
                </Link>
                <div className={styles.TopNavButton}>
                    <LinkButton href="/project-planner" label="Start your project" size="small" icon={<IconArrowRight size="24px" stroke="slate-900" />} />
                </div>
            </div>
        </nav>
    );
};

export default TopNav;

// TODO: Could refactor the button and link into some sort of child component as they're identical in style, however ones a button ones a link
